import { Injectable } from '@angular/core';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  id,
} from '@cca-infra/common';
import { BaseApiService, HttpContextBuilder } from '@cca-infra/core';
import {
  CargoValueRangeViewModel,
  EnumOptionViewModel,
  GetFilteredVehicleTypesRequest,
  GroupTreeItemV2,
  HandleUnitTypeViewModel,
  HazardCodesViewModel,
  SequenceTimeOptionsViewModel,
  SimpleEnterpriseGroupsViewModel,
  V2EnterpriseGroupsViewModel,
  VehicleTypeV2ViewModel,
  SequenceUserViewModel,
  EnterpriseGroupViewModel,
  SequenceContainerShippingLinesViewModel,
  SequenceContainerTypeViewModel,
  SequenceShippingRequirementViewModel,
} from '../model';
import { SimpleUserGroupsDerivedViewModel } from '../derived';

export interface ShipperEnterpriseOption {
  id: id;
  name: string;
  canBeSelected: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SequenceOptionsService extends BaseApiService {
  constructor() {
    super(`sequence.v1.options`);
  }

  getShipperEnterprises(userStateId?: id | null) {
    return this.http.get<EnterpriseGroupViewModel[]>(
      `${this.url}get-shipper-enterprises/${userStateId}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getCarrierEnterprises() {
    return this.http.get<EnterpriseGroupViewModel[]>(
      `${this.url}get-carrier-order-enterprises`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  /**@deprecated */
  getCargoValues(userStateId?: id | null) {
    return this.http.get<CargoValueRangeViewModel[]>(
      `${this.url}get-cargo-value-range/${userStateId}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getPalletTypes(userStateId?: id | null) {
    return this.http.get<HandleUnitTypeViewModel[]>(
      `${this.url}get-handleunit-types/${userStateId}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getTimingOptions(
    sequenceName: string,
    pickupDeliveryType: number,
    isNLCourier: boolean,
  ) {
    return this.http.post<SequenceTimeOptionsViewModel[]>(
      `${this.url}get-time-options/`,
      {
        sequenceName,
        pickupDeliveryType,
        isNLCourier,
      },
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getSubGroups(sequenceName: string) {
    return this.http.get<GroupTreeItemV2[]>(
      `${this.url}group-treeview/${sequenceName}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getVehicleTypes(sequenceName: string, entityId?: string) {
    if (entityId) {
      return this.http.get<VehicleTypeV2ViewModel[]>(
        `${this.url}get-vehicle-types/sequence/${sequenceName}/${entityId}`,
        {
          context: HttpContextBuilder({
            sequence: true,
          }),
        },
      );
    } else {
      return this.http.get<VehicleTypeV2ViewModel[]>(
        `${this.url}get-vehicle-types/sequence/${sequenceName}`,
        {
          context: HttpContextBuilder({
            sequence: true,
          }),
        },
      );
    }
  }

  getShippingRequirements(sequenceName: string) {
    return this.http.get<SequenceShippingRequirementViewModel[]>(
      `${this.url}get-shipping-requirements/${sequenceName}`,
    );
  }

  getFilteredHazardCodes(searchText: string) {
    return this.http.get<HazardCodesViewModel[]>(
      `${this.url}get-filtered-hazard-codes/${searchText}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getUserOptions(sequenceName: string) {
    return this.http.get<SequenceUserViewModel[]>(
      `${this.url}get-sequence-user-options/${sequenceName}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  /**@deprecated */
  getBookerOptions(sequenceName: string) {
    return this.http.get<SequenceUserViewModel[]>(
      `${this.url}get-booker-options/${sequenceName}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getHandleUnitTypes(userStateId?: id | null) {
    return this.http.get<HandleUnitTypeViewModel[]>(
      `${this.url}get-handleunit-types/${userStateId}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getNonPalletHandleUnitTypes(userStateId?: id | null) {
    return this.http.get<HandleUnitTypeViewModel[]>(
      `${this.url}get-non-pallet-handleunit-types/${userStateId}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getFilteredVehicleTypes(
    filteredVehicleTypes: GetFilteredVehicleTypesRequest,
  ) {
    return this.http.post<VehicleTypeV2ViewModel[]>(
      `${this.url}get-filtered-vehicletypes`,
      filteredVehicleTypes,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getPaginatedCarriersManagedTransEnterpriseGroups(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<V2EnterpriseGroupsViewModel>>(
      `${this.url}get-paginated-carrier-enterprises-for-managed-transport`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getAllCarriersManagedTransEnterpriseGroups() {
    return this.http.post<SimpleUserGroupsDerivedViewModel[]>(
      `${this.url}get-all-carrier-groups-for-managed-trans-tender`,
      { searchText: '' },
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getCarrierGroupsForManagedTransport(enterpriseId: id) {
    return this.http.get<SimpleEnterpriseGroupsViewModel[]>(
      `${this.url}get-carrier-groups-for-managed-transport/${enterpriseId}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  getContainerTypes(sequenceName: string) {
    return this.http.get<SequenceContainerTypeViewModel[]>(
      `${this.url}get-container-types/${sequenceName}`,
    );
  }

  getShippingLines(sequenceName: string) {
    return this.http.get<SequenceContainerShippingLinesViewModel[]>(
      `${this.url}get-container-shipping-lines/${sequenceName}`,
    );
  }

  getSequenceServiceOptions(sequenceName: string) {
    return this.http.get<EnumOptionViewModel[]>(
      `${this.url}sequence-service-options/${sequenceName}`,
    );
  }
}
